import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import DownloadButton from "Components/blocks/buttons/DownloadButton";
import FilterBar from "Components/blocks/FilterBar";
import Loading from "Components/blocks/Loading";
import EmptyState from "Components/blocks/EmptyState";
import Total from "Components/blocks/Total";
import { Table, Cell, createColumns, FixedCell, Field } from "@plan";
import { useFilters, useApi } from "@hooks";
import eventTracker from "Blocks/tracker.js";
import { gql, useQuery, ApolloProvider } from "@apollo/client";
import { useApollo } from "@hooks/useApollo";

import util, {
  getCurrentMonth,
  formatDecimal,
  joinAsCSV,
  downloadAsCSV,
} from "Utils/util";
import styles from "./reports.module.css";

const getExpensesV2Flag = gql`
  query featureFlags {
    featureFlags {
      expensesV2
    }
  }
`;

const createReimbursableLink = (profileId, beginDate, endDate) => {
  const url = "/reports/expenses";
  const urlWithParams = `${url}?dateRange[beginDate]=${beginDate}&dateRange[endDate]=${endDate}&profileIds[0]=${profileId}&expenseChargeTypes[0]=reimbursable`;

  return urlWithParams;
};

const createExpenseReportLinkExpensesV2 = (profileId, beginDate, endDate) => {
  const url = "/timesheets/expenses";
  const datePreset = {
    "Custom Dates": {
      from: new Date(beginDate),
      to: new Date(endDate),
    },
  };

  const stringifiedDates = `datePreset%5Blabel%5D=Custom%20Dates&datePreset%5Bvalue%5D=Custom%20Dates&datePreset%5Bfrom%5D=${beginDate}T05%3A00%3A00.000Z&datePreset%5Bto%5D=${endDate}T05%3A00%3A00.000Z&datePreset%5B`;
  const urlWithParams = `${url}?${stringifiedDates}&profileIds[0]=${profileId}`;

  return urlWithParams;
};

function PayrollContent({ queryParams }) {
  const { data: featureFlagData } = useQuery(getExpensesV2Flag);

  const expensesV2Enabled = Boolean(featureFlagData?.featureFlags.expensesV2);

  // Set the current month date range
  const thisMonth = getCurrentMonth();

  const { activeFilters, updateDates } = useFilters({
    initialActiveFilters: {
      ...thisMonth,
      ...queryParams,
    },
  });

  // Request the data
  const [data, loading, error, changeParams] = useApi(`/reports/payroll_data`, {
    ...activeFilters,
  });

  // Set Export Button Load State
  const [csvLoading, setCsvLoading] = useState(false);

  // Update the API query when filters change
  useEffect(() => {
    changeParams(activeFilters);
  }, [activeFilters]);

  if (error) return <EmptyState title="Error Fetching Data" />;
  if (loading === "fetching") return <Loading title="Loading..." />;

  const { data: tableData } = data;
  const { overheads } = data;

  const generatedColumns = createColumns({
    columns: overheads,
    type: "hours",
    options: {
      headerClass: "page-table__title page-table__cell--align-right",
      Cell: ({ cell }) => (
        <Cell type="right" value={formatDecimal(cell.value)} />
      ),
      Footer: ({ column }) => (
        <Cell
          type="right"
          value={<Total data={tableData} val={column.Header} format="hours" />}
        />
      ),
    },
  });

  const generateCsv = () => {
    const payrollReport = data.data;
    const overheads = data.overheads.map((overhead) => overhead.name);
    const exportHeaders = [
      "Employee Name",
      "Reimbursables",
      "Total Hours",
      "Work Hours",
      ...overheads,
    ];
    const exportData = payrollReport.map((person) => {
      const totalHours = util.toFloat(person.total_hours);
      const overheadHours = overheads.map((oh) => util.toFloat(person[oh]));
      return [
        person.name,
        person.reimbursable,
        totalHours,
        person.amount,
        ...overheadHours,
      ];
    });
    const exportCSV = joinAsCSV(exportHeaders, exportData);
    const fileName = `payroll_data_${activeFilters.begin_date}_to_${activeFilters.end_date}.csv`;
    downloadAsCSV(exportCSV, fileName);

    eventTracker("downloadedPayrollReport", {
      beginDate: activeFilters.begin_date,
      endDate: activeFilters.end_date,
    });
  };

  const columns = [
    {
      Header: "Employee",
      headerClass: "page-table__title page-table__column--fixed-left",
      footerClass: "page-table__column--fixed-left",
      classes:
        "page-table__cell page-table__column--fixed-left page-table__cell--nowrap",
      sticky: "left",
      Cell: ({ cell }) => <FixedCell>{cell.value}</FixedCell>,
      accessor: "name",
      Footer: () => (
        <FixedCell>
          <span style={{ fontWeight: "700", textTransform: "none" }}>
            Total
          </span>
        </FixedCell>
      ),
    },
    {
      id: "Reimbursables",
      Header: "Reimbursables",
      headerClass: "page-table__title page-table__cell--align-right",
      accessor: "reimbursable",
      Cell: ({ cell }) => {
        const { begin_date, end_date } = activeFilters;
        const profileId = cell.row.original.id;

        const urlWithParams = expensesV2Enabled
          ? createExpenseReportLinkExpensesV2(profileId, begin_date, end_date)
          : createReimbursableLink(profileId, begin_date, end_date);

        const LinkWithValue = () => (
          <a href={urlWithParams} target="_blank" rel="noreferrer">
            {util.toCurrencyWithCents(cell.value)}
          </a>
        );

        return (
          <Cell type="right" value={cell.value ? <LinkWithValue /> : "--"} />
        );
      },
      Footer: () => (
        <Cell
          type="right"
          value={
            <Total data={tableData} val="reimbursable" format="currency" />
          }
        />
      ),
    },
    {
      id: "Total Hours",
      Header: "Total Hours",
      headerClass: "page-table__title page-table__cell--align-right",
      accessor: "total_hours",
      Cell: ({ cell }) => (
        <Cell type="right" value={formatDecimal(cell.value)} />
      ),
      Footer: () => (
        <Cell
          type="right"
          value={<Total data={tableData} val="total_hours" format="hours" />}
        />
      ),
    },
    {
      Header: "Work Hours",
      headerClass: "page-table__title page-table__cell--align-right",
      accessor: "amount",
      Cell: ({ cell }) => (
        <Cell type="right" value={formatDecimal(cell.value)} />
      ),
      Footer: () => (
        <Cell
          type="right"
          value={<Total data={tableData} val="amount" format="hours" />}
        />
      ),
    },
    ...generatedColumns,
  ];

  return (
    <>
      <FilterBar>
        <Field
          as="dates"
          startDate={moment(activeFilters.begin_date, "YYYY-MM-DD")}
          startDateId="start"
          endDate={moment(activeFilters.end_date, "YYYY-MM-DD")}
          endDateId="end"
          onDateChange={updateDates}
          size="small"
          classes={styles.datePicker}
        />
        <div className={styles.exportButton}>
          <DownloadButton
            loading={csvLoading}
            action={generateCsv}
            disabledWhileLoading
          />
        </div>
      </FilterBar>
      <div className="page">
        <section className="grid">
          <Table
            as="sticky"
            data={tableData}
            columns={columns}
            emptyState="No Payroll for this Date Range"
            loading={loading}
          />
        </section>
      </div>
    </>
  );
}

PayrollContent.propTypes = {
  queryParams: PropTypes.shape({}),
};
PayrollContent.defaultProps = {
  queryParams: {},
};

const Payroll = ({ queryParams }) => {
  const apolloClient = useApollo();
  return (
    <ApolloProvider client={apolloClient}>
      <PayrollContent queryParams={queryParams} />
    </ApolloProvider>
  );
};

export default Payroll;
