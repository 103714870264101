
const digest = 'aaf14f870c6030b549659509601b02774a4c7a0a9b3ae53d8fe8d6b4520031c0';
const css = `._cardWrapper_rvpkl_1 {
  min-height: var(--row-height);
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: 0.25rem 0.75rem;
  width: 100%;
}

._cardWrapper_rvpkl_1._row_rvpkl_12 {
  border-bottom: 1px solid var(--color--neutral-20);
  padding: 0.5rem;
}

._cardWrapper_rvpkl_1._row_rvpkl_12:first-child {
  padding-top: 0.5rem;
}

._emptyCard_rvpkl_21 {
  transform-origin: center top;
}

._cardWrapper_rvpkl_1._open_rvpkl_25,
._emptyCard_rvpkl_21._open_rvpkl_25 {
  background: var(--color-legacy-gray-1);
  border-bottom: 1px solid var(--color--neutral-20);
  border-top: 1px solid var(--color--neutral-20);
  padding-top: 0.75rem;
}

._teamCard_rvpkl_33._open_rvpkl_25 {
  padding: 0.75rem 0;
  border-bottom: 1px solid var(--color--neutral-20);
  border-top: 1px solid var(--color--neutral-20);
}

._cardWrapper_rvpkl_1._open_rvpkl_25:first-child,
._emptyCard_rvpkl_21._open_rvpkl_25:first-child,
._teamCard_rvpkl_33._open_rvpkl_25:first-child {
  border-top: none;
}

._emptyCard_rvpkl_21._gridRow_rvpkl_45,
._cardWrapper_rvpkl_1._gridRow_rvpkl_45 {
  border-bottom: 1px solid var(--color--neutral-20);
}

._card_rvpkl_1 {
  align-items: center;
  background: var(--color-white);
  border: 1px solid var(--color--neutral-20);
  border-radius: 4px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 64px;
  justify-content: space-between;
  max-width: 100%;
  will-change: transform;
  transition: all 0.2s var(--transition);
  width: 100%;
  overflow: hidden;
}

._allocatedByUser_rvpkl_65 {
  position: absolute;
  top: 0;
  right: 4px;
}

._card_rvpkl_1:hover {
  box-shadow: 0 0 1.1rem -0.75rem var(--color-legacy-gray-6);
  transform: scale(1.02);
}

._teamWrapper_rvpkl_76 {
  transform-origin: "center top";
}
._teamCard_rvpkl_33 {
  height: 100%;
  align-items: center;
  background: var(--color-white);
  display: grid;
  grid-template-columns: minmax(0, 1fr) 64px;
  justify-content: space-between;
  transition: box-shadow 0.25s ease-in-out;
  border-bottom: 1px solid var(--color--neutral-20);
  width: 100%;
  max-width: 100%;
}

._emptyCard_rvpkl_21 {
  height: var(--row-height);
  transition: height 0.2s var(--transition);
}

._teamCard_rvpkl_33._ghosted_rvpkl_97 {
  opacity: 0;
}

._teamCard_rvpkl_33._ghosted_rvpkl_97._gridRow_rvpkl_45 {
  opacity: 0.5;
  pointer-events: all;
}

._ghosted_rvpkl_97 {
  opacity: 0.66;
  background: var(--color-legacy-gray-1);
  pointer-events: none;
}

._emptyCard_rvpkl_21._ghosted_rvpkl_97,
._cardWrapper_rvpkl_1._ghosted_rvpkl_97 {
  background: var(--color-legacy-gray-2);
}

._info_rvpkl_117 {
  display: flex;
  flex-direction: column;
  justify-items: center;
  max-width: 100%;
  padding: 0.75rem 0 0.75rem 0.75rem;
  cursor: pointer;
}

._name_rvpkl_126,
._projectLink_rvpkl_127 {
  color: var(--color-neutral-10);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1;
  margin: 0.125rem 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

._projectLink_rvpkl_127:hover {
  text-decoration: underline;
}

._abbrev_rvpkl_143 {
  color: var(--color-neutral-40);
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-content: start;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
._abbrevText_rvpkl_156 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._roleIcon_rvpkl_162 {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-left: 0.5rem;
  padding-top: 1px;
}

._allocation_rvpkl_170 {
  margin-right: 0.75rem;
  width: 100%;
}

._role_rvpkl_162 {
  align-items: flex-start;
  border-top: 1px solid var(--color--neutral-20);
  color: var(--color--neutral-10);
  display: flex;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  padding: 0.5rem 0.75rem;
  width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._roleProgress_rvpkl_192 {
  position: relative;

  border-bottom: 1px solid var(--color--neutral-20);
  padding: 0 0.75rem 0.75rem;
  width: 100%;
}

._roleBudget_rvpkl_200 {
  position: relative;

  padding: 0.75rem;
  width: 100%;
}

._expanded_rvpkl_207 {
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 2;
  padding: 0;
  width: 100%;
}

._row_rvpkl_12 {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0.25rem 0;
  width: 100%;
}

._row_rvpkl_12._oneColumn_rvpkl_223 {
  grid-template-columns: 1fr 2fr;
}

._title_rvpkl_227 {
  color: var(--color--neutral-10);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}

._budget_rvpkl_235,
._hours_rvpkl_236,
._value_rvpkl_237 {
  color: var(--color--neutral-10);
  display: flex;
  font-size: 0.75rem;
  justify-content: flex-end;
  line-height: 1;
  text-align: right;
  font-weight: 600;
  width: 100%;
}

._value_rvpkl_237,
._hours_rvpkl_236 {
  color: var(--color-neutral-10);
}

._hours_rvpkl_236 {
  text-transform: uppercase;
}

._overage_rvpkl_257 {
  font-size: 0.75rem;
}

._overage_rvpkl_257 hr {
  border: none;
  border-top: 1px solid var(--color-legacy-gray-3);
}

._overageRow_rvpkl_266 {
  display: flex;
  justify-content: space-between;
}

._overageRow_rvpkl_266 > *:first-of-type {
  margin-right: 0.5rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"cardWrapper":"_cardWrapper_rvpkl_1","row":"_row_rvpkl_12","emptyCard":"_emptyCard_rvpkl_21","open":"_open_rvpkl_25","teamCard":"_teamCard_rvpkl_33","gridRow":"_gridRow_rvpkl_45","card":"_card_rvpkl_1","allocatedByUser":"_allocatedByUser_rvpkl_65","teamWrapper":"_teamWrapper_rvpkl_76","ghosted":"_ghosted_rvpkl_97","info":"_info_rvpkl_117","name":"_name_rvpkl_126","projectLink":"_projectLink_rvpkl_127","abbrev":"_abbrev_rvpkl_143","abbrevText":"_abbrevText_rvpkl_156","roleIcon":"_roleIcon_rvpkl_162","allocation":"_allocation_rvpkl_170","role":"_role_rvpkl_162","roleProgress":"_roleProgress_rvpkl_192","roleBudget":"_roleBudget_rvpkl_200","expanded":"_expanded_rvpkl_207","oneColumn":"_oneColumn_rvpkl_223","title":"_title_rvpkl_227","budget":"_budget_rvpkl_235","hours":"_hours_rvpkl_236","value":"_value_rvpkl_237","overage":"_overage_rvpkl_257","overageRow":"_overageRow_rvpkl_266"};
export { css, digest };
  