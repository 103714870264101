
const digest = 'bae2cb793034c960261318eef2e5f5e02ffe6f3a049f30948525392f592752be';
const css = `._card_rrs2e_1 {
  background: #fff;
  border: 1px solid #e7e7ed;
}

._withPadding_rrs2e_6 {
  padding: 1rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"card":"_card_rrs2e_1","withPadding":"_withPadding_rrs2e_6"};
export { css, digest };
  