
const digest = '9d8ef6ee44dc9bea39ccde5013c651dc6d3bed94723ba729941d1bbb71b717af';
const css = `.schedule .body-content {
  padding: 0;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {};
export { css, digest };
  