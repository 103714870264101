
const digest = '8ac6a6b453965e07c1aa3f45b87c739dbeaf2dff8ad90e9279f5481898e62077';
const css = `._timesheetTableActions_hd6fx_1 {
  display: flex;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"timesheetTableActions":"_timesheetTableActions_hd6fx_1"};
export { css, digest };
  